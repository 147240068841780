import { Routes } from '@angular/router';
import { InteractiveComponent } from './views/interactive/interactive.component';
import { HomeComponent } from './views/home/home.component';
import { ArticleDetailComponent } from './views/article/article-detail.component';
import { ErrorComponent } from './views/error/error.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import {RealizationDetailComponent} from "./views/realization/realization-detail.component";

export const appRoutes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'interactive', component: InteractiveComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'article/:id', component: ArticleDetailComponent },
    { path: 'realization/:id', component: RealizationDetailComponent },
    { path: '**', component: ErrorComponent }
  ];